import { graphql } from "gatsby"
import React from "react"
import { AnchorButton } from "../components/Button"
import { CloudinaryImage } from "../components/CloudinaryImage"
import { ContactSection } from "../components/ContactSection"
import { Counter } from "../components/Counter"
import { Gallery } from "../components/Gallery"
import { Heading } from "../components/Heading"
import { HeadingBlock } from "../components/HeadingBlock"
import { Hero } from "../components/Hero"
import { Layout } from "../components/Layout"
import { List } from "../components/List"
import { PackageSection } from "../components/PackageSection"
import { Row } from "../components/Row"
import { SEO } from "../components/SEO"
import { Text } from "../components/Text"
import { TextImageSection } from "../components/TextImageSection"
import { TipsSection } from "../components/TipsSection"
import ogImage from "../images/og-image.jpg"
import "../lib/kirby.interface"

interface HomeProps {
  title: string
  introText: string
  mainImage: { url: string; alt: string }
  uspTitle: string
  uspSubtitle: string
  uspIntro: string
  uspBigImage: { url: string; alt: string }
  uspSmallImage: { url: string; alt: string }
  usps: Array<{ title: string; text: string }>
  packageImage: { url: string; alt: string }
  galleryTitle: string
  gallerySubtitle: string
  galleryIntro: string
  galleryImages: Array<{ url: string; alt: string; orientation: string }>
  seoTitle: string
  seoDescription: string
}

const IndexPage = ({ data }: Kirby.Data<{ home: HomeProps }>) => {
  const content = data.kirby.home

  return (
    <Layout>
      <SEO
        title={content.seoTitle || content.title}
        description={content.seoDescription}
        meta={[
          {
            property: "og:image",
            content: ogImage,
          },
        ]}
      />
      <Hero url={content.mainImage.url} alt={content.mainImage.alt}>
        <Heading diap as="h1" size={40}>
          {content.title}
        </Heading>

        <Text size={18}>{content.introText}</Text>

        <AnchorButton style={{ marginTop: "1rem" }} large href="/#pakketten">
          Bekijk pakketten
        </AnchorButton>
      </Hero>

      <TextImageSection
        bigImgId={content.uspBigImage}
        smallImgId={content.uspSmallImage}
        heading={{
          title: content.uspTitle,
          subtitle: content.uspSubtitle,
          children: (
            <div dangerouslySetInnerHTML={{ __html: content.uspIntro }} />
          ),
        }}
      >
        <List>
          {content.usps.map((usp) => (
            <li key={usp.title}>
              <Heading size={16} as="h4">
                {usp.title}
              </Heading>
              <p>{usp.text}</p>
            </li>
          ))}
        </List>
      </TextImageSection>

      <PackageSection backgroundImageUrl={content.packageImage.url} />

      <Row>
        <HeadingBlock title="Gezien bij Hoefwijzer" subtitle="Video">
          <p>
            In deze video krijgt van Fenna van Hoefwijzer een schriktraining.
            Moos, Noura en alle paarden van haar stalgenoten deden mee. Gaat dat
            wel goed? Bekijk de video!
          </p>
        </HeadingBlock>

        <iframe
          width="100%"
          src="https://www.youtube.com/embed/WLG6H-WY81k?enablejsapi=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ aspectRatio: "16/9", minHeight: "400px" }}
        />
      </Row>

      <Counter />

      <TipsSection />

      <Row>
        <HeadingBlock
          title={content.galleryTitle}
          subtitle={content.gallerySubtitle}
        >
          <div dangerouslySetInnerHTML={{ __html: content.galleryIntro }} />
        </HeadingBlock>
      </Row>

      <Gallery>
        {content.galleryImages.map(({ url, alt, orientation }) => (
          <CloudinaryImage
            className={orientation}
            key={url}
            publicId={url}
            alt={alt}
            width={500}
            crop="scale"
            gravity="face"
          />
        ))}
      </Gallery>

      <ContactSection />
    </Layout>
  )
}

export const query = graphql`
  query {
    kirby {
      home {
        title
        introText
        mainImage {
          url
          alt
        }
        uspTitle
        uspSubtitle
        uspIntro
        uspBigImage {
          url
          alt
        }
        uspSmallImage {
          url
          alt
        }
        usps {
          title
          text
        }
        packageImage {
          url
          alt
        }

        galleryTitle
        gallerySubtitle
        galleryIntro
        galleryImages {
          url
          alt
          orientation
        }

        seoTitle
        seoDescription
      }
    }
  }
`

export default IndexPage
