import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import useIsInViewport from "use-is-in-viewport"
import Theme from "../lib/Theme"
import { Row } from "./Row"
import { PaardRuiterCombinatie, Rookwolk, Skydancer } from "./icons"

const convert = (value: number, factor: number = 1) =>
  Math.round(value * factor).toLocaleString("nl")

export const Counter = () => {
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 50 })
  const combinationsTarget = 673
  const [combinations, setCombinations] = useState(combinationsTarget)

  const inc = combinationsTarget / 50

  useEffect(() => {
    setCombinations(0)
  }, [])

  useEffect(() => {
    if (!isInViewport) {
      return
    }
    if (combinations < combinationsTarget) {
      setTimeout(() => {
        setCombinations((s) => Math.min(combinationsTarget, s + inc))
      }, 50)
    }
  }, [combinations])

  useEffect(() => {
    if (isInViewport && combinations === 0) {
      setCombinations(1)
    }
  }, [isInViewport])

  return (
    <Bg>
      <Row>
        <Wrap>
          <Stat ref={targetRef}>
            <PaardRuiterCombinatie />
            <span>{convert(combinations)}</span>
            <p>
              Combinaties
              <br />
              stoerder gemaakt
            </p>
          </Stat>
          <Stat>
            <Rookwolk />
            <span>{convert(combinations, 1.62)}</span>
            <p>
              Rookwolken
              <br />
              geblazen
            </p>
          </Stat>
          <Stat>
            <Skydancer />
            <span>{convert(combinations, 0.81)}</span>
            <p>
              Skydancer
              <br />
              lanceringen
            </p>
          </Stat>
        </Wrap>
      </Row>
    </Bg>
  )
}

const Bg = styled.div`
  background: ${Theme.Colors.purple.s100};
  padding: 4rem 0;
  margin: 4rem 0;
`

const Wrap = styled.div`
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${Theme.Mediaquery.medium.min} {
    flex-direction: row;
    justify-content: center;
  }
`

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 5rem;

  ${Theme.Mediaquery.medium.max} {
    margin: 2rem 0;
  }

  svg {
    display: block;
    width: 10rem;
    height: 10rem;
  }

  span {
    display: block;
    font-size: 3.5rem;
    font-weight: ${Theme.Font.body.bold};
    margin: 0.2rem 0;
    line-height: 1;

    min-width: 9rem;
  }

  p {
    margin: 0.3rem auto 0;
    line-height: 1.2;
  }
`
