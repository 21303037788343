import styled from "@emotion/styled"
import Theme from "../lib/Theme"

export const Gallery = styled.section`
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .portrait {
    grid-row: span 2;
  }

  ${Theme.Mediaquery.medium.max} {
    overflow: auto;
    width: 100vw;
    height: 16rem;
    display: flex;

    img {
      display: inline-block;
    }
  }

  ${Theme.Mediaquery.medium.min} {
    display: grid;
    grid-auto-flow: dense;
    gap: 2px;

    grid-template-columns: 1fr;
    grid-auto-rows: auto;

    img {
      border-radius: 2px;
    }

    grid-template-columns: repeat(3, 1fr);
  }

  ${Theme.Mediaquery.large.min} {
    grid-template-columns: repeat(4, 1fr);
  }
`
