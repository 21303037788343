import styled from "@emotion/styled"
import { graphql, Link, useStaticQuery } from "gatsby"
import React, { FC } from "react"
import Theme from "../lib/Theme"
import { rgba } from "../lib/utils"
import { LinkButton } from "./Button"
import { CloudinaryImage } from "./CloudinaryImage"
import { Heading } from "./Heading"
import { PackageCard } from "./PackageCard"
import { Row } from "./Row"

export const PackageSection: FC<{ backgroundImageUrl: string }> = ({
  backgroundImageUrl,
}) => {
  const data = useStaticQuery(graphql`
    query {
      kirby {
        allPackage(limit: 4) {
          id
          isHorizontal
          excerpt
          title
          price
          pricePerPerson
        }
      }
    }
  `)

  const horizontal = data.kirby.allPackage.find((p) => p.isHorizontal)
  const items = data.kirby.allPackage.filter((p) => !p.isHorizontal)

  return (
    <Wrap>
      <PackageImage
        publicId={backgroundImageUrl}
        alt=""
        width={1000}
        crop="scale"
      />
      <Content id="pakketten">
        {horizontal && (
          <Row>
            <HorizontalPackage>
              <HorizontalPackageTitle>
                <Heading as="h3">{horizontal.title}</Heading>
                <p>{horizontal.excerpt}</p>
              </HorizontalPackageTitle>

              <HorizontalPackagePrice>
                vanaf <strong>€{horizontal.price}</strong>
                {horizontal.pricePerPerson && <>per persoon, per uur</>}
              </HorizontalPackagePrice>

              <LinkButton
                to={`/${horizontal.id}`}
                data-track-content
                data-content-name="PackageCard"
                data-content-piece={horizontal.title}
              >
                Meer informatie
              </LinkButton>
            </HorizontalPackage>
          </Row>
        )}

        <PackageRow>
          {items.map(({ id }) => (
            <PackageCard key={id} id={id} />
          ))}
        </PackageRow>

        <Sidenote>
          * Genoemde prijzen zijn vanaf 6 personen per uur en exclusief{" "}
          <Link to="/reiskosten">reiskosten</Link>.<br />
          Op alle pakketten zijn de{" "}
          <Link to="/algemene-voorwaarden">Algemene Voorwaarden</Link> van
          toepassing.
        </Sidenote>
      </Content>
    </Wrap>
  )
}

const Wrap = styled.section`
  position: relative;
  background: ${Theme.Colors.purple.s100};
  margin: 4rem 0;
`

const PackageRow = styled(Row)`
  display: grid;
  gap: 4rem 2rem;

  ${Theme.Mediaquery.medium.min} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const PackageImage = styled(CloudinaryImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`

const Content = styled.div`
  position: relative;
  padding: 10rem 0 7rem 0;
  background-image: linear-gradient(
    ${rgba(Theme.Colors.purple.s100, 0)} 0%,
    ${Theme.Colors.purple.s100} 100%
  );
`

const Sidenote = styled.p`
  margin: 5rem 1rem 0 1rem;

  text-align: center;
  color: #fff;
  opacity: 0.75;
  font-size: 0.9rem;

  a {
    color: #fff;
  }
`

const HorizontalPackage = styled.div`
  position: relative;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 5rem;
  padding: 2rem;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 8px 8px rgba(0, 0, 0, 0.11),
    0 16px 16px rgba(0, 0, 0, 0.11), 0 32px 32px rgba(0, 0, 0, 0.11);

  ${Theme.Mediaquery.medium.min} {
    flex-direction: row;
    align-items: center;
  }
`

const HorizontalPackageTitle = styled.div`
  flex: 1;
  text-align: left;

  ${Heading} {
    margin-bottom: 0.25rem;
  }

  p {
    margin: 0;
    font-size: 0.9rem;
  }
`

const HorizontalPackagePrice = styled.p`
  margin: 0 0 1rem 0;
  color: ${Theme.Colors.purple.s100};
  padding: 0 2rem;

  strong {
    display: block;
    font-size: 2.5rem;
    margin: 0 0.5rem;
  }

  ${Theme.Mediaquery.medium.min} {
    margin: 0;
    display: flex;
    align-items: center;

    strong {
      display: inline-block;
    }
  }
`
