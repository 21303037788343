import styled from "@emotion/styled"
import bulletSrc from "../images/bullet.svg"
import { Heading } from "./Heading"

export const List = styled.ul`
  margin-top: 1rem;
  list-style: url(${bulletSrc});

  ${Heading} {
    margin-bottom: 0.25rem;
  }

  p {
    margin-top: 0;
  }
`
