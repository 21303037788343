import * as React from "react"

function SvgCaretRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="currentColor">
        <path d="M97.5 50L62.602 8.102H41.899L76.801 50 41.899 91.898h20.703z" />
        <path d="M23.199 8.102H2.5L37.398 50 2.5 91.898h20.699L58.101 50z" />
      </g>
    </svg>
  )
}

export default SvgCaretRight
